.ui.form .modalDocumentRequest input {
  height: unset !important;
}
.ui {
  -webkit-box-sizing: unset;
  box-sizing: border-box;
}

.divider-modal-document {
   border-bottom: 1px solid rgba(34, 36, 38, .15);
}

.divider-vertical-modal-document {
    border-right: 1px solid rgba(34, 36, 38, .15);
 }