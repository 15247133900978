div#clients-detail-container {
    margin: 50px;
    background: #fff;
    width: 100%;
    border-radius: 16px;
    box-shadow: 0px 1px 5px #e0e0e0;
    font-family: 'Inter';
}

.cards-dp-info{
    display: flex;
    justify-content: flex-start;
    padding: 0 20px;
}

div#clients-detail-container-body {
    margin-top: 10px;
    padding: 20px 15px;
    overflow-x: auto;
}

div#clients-detail-container-header {
    padding: 25px 35px;
    background: #fafafa;
    border-radius: 15px 15px 0px 0px;
    border-bottom: 1px solid #E0E0E0;
    color: #4e4b58;
}

div#clients-detail-container-header h2 {
  font-size: 18px;
  color: #4E4B59;
}

.client-detail-container-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #9997A0;
}

.client-detail-container-body {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4E4B59;
}