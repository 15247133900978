.documents {
  font-family: Arial, sans-serif;
}

.file-explorer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.directory-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
  transition: background-color 0.3s, border 0.3s; /* Transição suave para destaque */
}

.folder,
.file {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  text-align: center;
}

.folder {
  width: 120px; /* Largura fixa para as pastas */
}

.file {
  width: 120px; /* Largura fixa para os arquivos */
}

.icon {
  font-size: 48px; /* Tamanho maior do ícone */
}

.name {
  margin-top: 20px;
  font-size: 14px;
}

.selected {
  background-color: #d0e0f0; /* Cor de fundo quando selecionado */
}

.file-list {
  padding-left: 20px;
}

.back-button {
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 5px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
}

/* Layout de grid para pastas */
.directory-grid {
  display: flex;
  flex-wrap: wrap;
}
