.box-forgot {
    width: 600px;
    height: 537px;
    background-image: url("../../../assets/bg-emailModal.png"); 
    background-size: cover;
}
.box-forgot h2 {
    margin-top: 134px !important;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    color: var(--login-lgt-gray);
}
.box-forgot .div-p {
    font-weight: normal;
    font-size: 16px;
    margin: 50px 44px 67px;
}

.box-forgot button {
    display: block;
    margin: 0 auto;
    width: 360px;
    height: 48px;
    background: #4F85F1;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
}

.box-forgot button:hover {
    background: #487add;
}
