#login-form {
    background: #fff;
    padding: 42px 56px 20px;
    border-radius: 8px;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    position: relative;
}
.title-form {
    text-align: start;
    width: 100%;
}

.title-form h2 {
    font-size: 28px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 10px;
}
#login-form h3 {
    font-weight: bold;
    font-size: 18px;
    color: var(--login-lgt-gray);
    margin-top: 16px;
    margin-bottom: 16px;
}
.title-form p {
    font-size: 1.1rem;
    font-weight: 400;
}
.title-form.forgot h2 {
    font-weight: bold;
    font-size: 32px !important;
    width: 352px;
}
div#login-logo img {
    width: 178px;
    height: 38.41px;
    padding-bottom: 47px;
}
#login-form button {
    max-width: 360px;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    padding: 0;
    margin-bottom: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    background-color: var(--advlogin-lgt-blue);
    text-transform: none;
}
#login-form .secondary  {
    background-color: var(--advlogin-drk-blue) !important;
}

img.third-party-login-img {
    width: 24px;
    margin-right: 20px;
}

.inverted{
    filter: invert(1);
}

a#whatsapp-caller img {
    background: #f14f5c;
    width: 44px;
    border-radius: 25px;
    padding: 5px;
    margin-right:20px;
}
a#whatsapp-caller {
    display: flex;
    align-items: center;
}
#login-form .ui.input, .ui.input>input {
    height: 40px !important;
    width: 360px !important;
    padding: 0 !important;
    flex: 1 !important;
}

#login-form label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    height: 24px;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{
    color: var(--advlogin-lgt-blue) !important;
}
#login-form .default {
    padding-top: 32px;
}
#login-form .input-content {
    margin-bottom: 15px;
    height: 63px;
}
.input-content .icon-password svg {
    color: var(--login-lgt-gray);
    width: 18px;
    height: 14px;
}
.input-content .icon-password {
    position: relative;
    top: 35px;
    left: 288px;
    color: var(--login-lgt-gray);
    width: 23px;
    height: 17px;
    cursor: pointer;
}
.input-content.confirm-pw .icon-password {
    position: relative;
    top: 35px;
    left: 218px;
    color: var(--login-lgt-gray);
    width: 23px;
    height: 17px;
    cursor: pointer;
}
.input-content.switch {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 20px !important;
}
.input-content.switch label{
   cursor: auto;
}
.input-content .iciELI{
    border: 1px dashed #D3D2D7 !important;
    background: rgba(233, 233, 233, 0.4);
}
.input-content .iciELI svg {
    display: none;
}
.input-content .iciELI .sc-hKwDye.kCudqJ span {
    background: #D3D2D7;
    border-radius: 4px;
    padding: 3px 18px;
    text-decoration: none;
}
.input-content.switch .css-7fnjh0{
    margin: 0;
    margin-right: 10px;
}
.default-direct {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    cursor: pointer;
}
.default-direct span {
    color: var(--advlogin-lgt-blue);
}
.default-direct.senha span{
    color: #501A2C !important;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}
.default-direct.senha .lock{
    color: var(--advlogin-lgt-blue);
    height: 23px;
    width: 23px;
    margin-right: 5px;
}
.default-direct p, .default-direct span {
    font-size: 16px;
    font-family: "Inter";
}
input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid var(--advlogin-lgt-blue);
    -webkit-box-shadow: 0 1px 0 0 var(--advlogin-lgt-blue);
    box-shadow: 0 1px 0 0 var(--advlogin-lgt-blue);
}
.finalizar-cadastro {
    margin-top: 20px;
    text-align: center;
}
.finalizar-cadastro span{
    font-size: 14px;
    color: var(--advlogin-lgt-blue);
    margin-top: 10px;
    cursor: pointer;
}
.input-content.select .placeholder {
    display: flex;
    align-items: center;
    color: var(--login-lgt-gray) !important;
}
.input-content.select .placeholder p {
    padding-left: 16px;
}
.input-content.select
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 9.94px 14px;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.ui.input.focus>input, .ui.input>input:focus {
    border-bottom: 1px solid var(--advlogin-lgt-blue) !important;
    -webkit-box-shadow: 0 1px 0 0 var(--advlogin-lgt-blue) !important;
    box-shadow: 0 1px 0 0 var(--advlogin-lgt-blue) !important;
    border-width: 1px !important;
    border-color: var(--advlogin-lgt-blue) !important;
}

.sc-hKwDye.kCudqJ span {
    font-size: 12px;
}

.language-placeholder {
  color: var(--login-lgt-gray);
  padding: 10px 0;
}

.locale-placeholder {
  color: var(--login-lgt-gray);
}

#demo-multiple-checkbox-language {
    padding: 2px 14px;
}

/* iv#login-form button:hover {
    background: #f14f5c;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

a.font-pinked.sub {
    color: #f14f5c;
    text-decoration: underline;
    font-weight: 600;
}

a#whatsapp-caller {
    color: #f14f5c;
} */