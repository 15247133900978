.demand-sidebar {
  padding-left: 20px !important;
  padding-right: 10px !important;
  padding-top: 30px !important;
  height: 89vh;
  border-right: 1px solid #d3d2d7;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.demand-sidebar .title {
  word-break: break-word;
  padding: 0px 20px 30px 10px;
}

.demand-sidebar .subtitle {
  text-align: center;
}

.demand-sidebar h3 {
  font-size: 1.75rem;
}

#demand-info h3 {
  font-size: 1.75rem;
}

.demand-sidebar h5 {
  font-size: 1.25rem;
}

.demand-sidebar h3,
.demand-sidebar h5,
.demand-sidebar h6,
#demand-info h3 {
  font-family: "Inter", sans-serif;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #4e4b59;
}

.options-container button {
  background: transparent;
  border: none;
  margin-bottom: 10px;
  font-weight: 500;
  cursor: pointer;
}

.options-container button:hover {
  color: #7683f6;
}

.options-container .row {
  margin-bottom: 0px !important;
}

.demand-sidebar .timeline-item {
  margin-top: 20px;
}

.demand-sidebar .timeline-item.row {
  min-height: 60px;
}

.border-0.m-0.p-0.left-bordered.col-8 {
  border-left: 1px solid #d3d2d7 !important;
  position: relative;
}
.timeline-item .nine.wide.column .bullet {
  position: absolute;
  left: -5px;
  margin: 0px !important;
  top: 0;
}

.timeline-item .nine.wide.column span {
  margin-left: 10px;
}

.timeline-item span {
  font-size: 1.1rem;
}

.timeline-item .nine.wide.column {
  border-left: 1px solid #d3d2d7 !important;
  position: relative;
}

span.bullet {
  border: 2px solid green;
  border-radius: 100%;
  height: 5px;
  width: 5px;
  color: transparent;
  background: green;
}

h1,
h2,
h3,
h4,
h6,
th,
strong {
  color: #4e4b59;
  font-family: "Inter", sans-serif;
}

#demand-v2-topbar {
  border-bottom: 1px solid #d3d2d7;
  margin-left: 0px;
  margin-top: 0px;
}

.attach-button {
  cursor: pointer;
}

.container-width-sbopen {
  min-height: 100vh !important;
}

#fluxos {
  height: 55vh;
  overflow-x: auto;
  overflow-x: hidden;
}

.status-chip {
  height: 40px;
}

.select-table,
.MuiFormControl-root,
.MuiOutlinedInput-notchedOutline {
  border: none !important;
  background-color: transparent !important;
  width: 100% !important;
  height: 30px;
  margin: 0px !important;
}

.MuiSelect-icon {
  left: 80% !important;
}

.justificativa {
  box-shadow: none;
  width: 524px;
  background-color: white;
  min-height: 224px;
  position: fixed !important;
  display: flex;
  top: 280px;
  left: 60%;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  z-index: 99999;
}
.justificativa.d-none {
  display: none !important;
}
.justificativa p {
  font-weight: normal;
  font-size: 14px;
  color: #9ca5fb;
  margin-top: 24px !important;
  margin: 0;
}
.justificativa textarea {
  width: 475px !important;
  min-height: 100px;
  max-height: 200px;
  padding: 15px;
  border: 1px solid #d3d2d7;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 16px;
  color: #000000;
}
.justificativa textarea:focus-visible {
  outline: 2px solid #d3d2d7;
  color: white;
  font-weight: 700;
  display: block;
  margin: 15px;
  margin-left: auto;
  color: #000000;
}
.justificativa button:disabled {
  background-color: #fafafa !important;
  color: #d3d2d7 !important;
}

.justificativa-text {
  text-align: end !important;
  position: absolute;
  width: 432px;
  margin: 0;
  font-size: 12px;
  color: #4e4b59;
  right: 40px;
}
.demand-obs {
  padding: 20px 35px;
}

.demand-awnsers {
  padding: 20px 35px;
}

.demand-obs {
  padding: 20px 35px;
}

.button-demand {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-top: 10px !important;
}
.ui.grid {
  padding-right: 18px;
  padding-left: 18px;
}
#fluxos * {
  box-sizing: content-box !important;
}

i.orange, i.green {
  background-color: unset !important;
}

#demand-request-documents {
  margin-top: 20px;
  padding: 50px;
  height: calc(100vh - 185px);
  overflow-y: auto;
}

#demand-request-documents .in_progress {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 16px;
  min-height: 100px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.18);
  padding: 20px;
  margin-bottom: 40px;
}

#demand-request-documents h3 {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight:600;
}

#demand-request-documents .debitBNZ {
  color: #9997A0;
  font-family: 'Inter';
  font-size: 14px;
  margin-bottom: 15px;
}

#demand-request-documents .valueBNZ {
  color: #F14F5C;
  font-family: 'Inter';
  font-size: 20px;
  font-weight: bold;
  padding-right: 10px;
}

#demand-request-documents .document {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight:600;
  margin-bottom: 10px;
}

#demand-request-documents .row.document {
  border-bottom: 1px solid rgba(34, 36, 38, .15);
  padding-bottom: 20px;
}

#demand-request-documents .model {
  color: #9997A0;
  font-family: 'Inter';
  font-size: 12px;
}

#demand-request-documents .send {
  display: flex;
  justify-content: center;
  align-items: center;
}

#demand-request-documents .comment {
  font-size: 12px;
  font-weight: normal;
  color: #F14F5C;
  font-family: 'Montserrat';
}