.searchbar  {
    margin: 25px auto;
}
.searchbar input {
    background: #FFFFFF;
    border: 1px solid #D3D2D7;
    border-radius: 8px;
    color: #9997A0;
    display: block;
    width: 100%;
}