/* Estilos gerais */
.card.card-reportsPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 10px;
}

#ReportsPage select {
    display: inline !important;
    max-width: 80px;
}

#ReportsPage .hide {
    display: none !important;
}

#ReportsPage {
    box-sizing: content-box !important;
}

#ReportsPage .text-right {
    text-align: right !important;
}