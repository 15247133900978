.banner{
    background-image: url("../../assets/bg-advcoruja.png");
    background-size: cover;
    width: 100%;
    border-radius: 12px;
    height: 97%;
    margin: 0 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.container-banner-img {
  width: 422px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;
}
.banner p {
    color: white !important;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    margin: 30px !important;
    margin-top: 0px !important;
}