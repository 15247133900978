@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: content-box;
}
html{
    font-family: 'Roboto' !important;
}

h2, label, p, a{
    font-family: "Inter";
}

a {
    color: #9997A0;
}

:root {
    --logo-red: #F14F5C;
    --login-drk-gray: #4E4B59;
    --login-gray: #7A7786;
    --login-lgt-gray: #9997A0;
    --advlogin-lgt-blue: #7683F6;
    --advlogin-drk-blue: #1A2C50;
}
.container-width {
    width: 90% !important;
    display: block;
    margin-left: 95px;
    transition: all ease 0.35s;
}

.container-width-open {
    width: 90% !important;
    display: block;
    margin-left: auto;
    transition: all ease 0.35s;
}


.container-width-sbopen {
    width: calc(100vw - 244px) !important;
    display: block;
    margin-left: auto;
    transition: all ease 0.35s;
}

input:not([type]):focus:not([readonly])+label, input[type=text]:not(.browser-default):focus:not([readonly])+label, input[type=password]:not(.browser-default):focus:not([readonly])+label, input[type=email]:not(.browser-default):focus:not([readonly])+label, input[type=url]:not(.browser-default):focus:not([readonly])+label, input[type=time]:not(.browser-default):focus:not([readonly])+label, input[type=date]:not(.browser-default):focus:not([readonly])+label, input[type=datetime]:not(.browser-default):focus:not([readonly])+label, input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label, input[type=tel]:not(.browser-default):focus:not([readonly])+label, input[type=number]:not(.browser-default):focus:not([readonly])+label, input[type=search]:not(.browser-default):focus:not([readonly])+label, textarea.materialize-textarea:focus:not([readonly])+label {
    color: var(--advlogin-lgt-blue);
}
.btn-red {
    font-family: "Inter";
    background: #F14F5C;
    border-radius: 4px;
    border: none;
    font-weight: bold;
    font-size: 14px;
    color: white;
    cursor: pointer;
}

.collapse:not(.show) {
      display: none;
}
  
.ui .modal {
    margin: auto !important;
    overflow: unset;
}

.ui * {
    box-sizing: border-box !important;
}

.semantic-divider {
    border-top: none !important;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.ajs-message, .ajs-dialog {
    font-size: 20px;
}

.ajs-ok {
    background-color: #4CAF50 !important;
    color: white !important;
    border: none !important;
    padding: 5px 5px !important;
    cursor: pointer !important;
}

.ajs-cancel {
    background-color: #E3E3E3 !important;
    color: black !important;
    border: none !important;
    padding: 5px 5px !important;
    cursor: pointer !important;
}

.ajs-ok:hover {
    background-color: #45a049 !important;
}

.ajs-cancel:hover {
    background-color: #C3C3C3 !important;
}
