.box.chat-modal {
    background-image: none !important;
    width: auto !important;
    height: auto;
    min-width: 600px;
    padding: 24px !important;
}
.box.chat-modal #table-modal tbody .tr-button td {
    height: 10px;
    padding: 6px;
    width: auto;
    border-top: none;
    border-bottom: none;
}
.box.chat-modal .ui.table thead th {
    padding-bottom: 0 !important;
}
.box.chat-modal #table-modal tr {
    border-bottom: none;
}
.box.chat-modal .chat {
    height: 130px;
    padding: 10px 0;
    overflow-y: auto;
    border-top: 1px solid #D3D2D7;
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}
.box.chat-modal .header-client{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
}
.box.chat-modal .header-client p{
    margin: 0;
    font-size: 14px;
    line-height: 150%;
}
.box.chat-modal .header-client span{
    color: var(--advlogin-lgt-blue);
    font-weight: 900;
    cursor: pointer;
}
.box.chat-modal .chat .baloon{
    max-width: 277px;
    width: fit-content;
    height: auto;
    padding: 10px 18px;
    text-align: start;
    background: #FAFAFA;
    border-radius: 8px;
    margin: 8px 0;
    font-size: 12px;
    color: #4E4B59;
    font-family: "Inter";
}
.box.chat-modal .ui.input {
    display: flex;
    margin-top: 12px;
}
.box.chat-modal .ui.input input{
    padding: 5px 15px !important;
    font-size: 12px;
    padding-right: 38px !important;
}
.box.chat-modal .pointer-chat{
    position: absolute;
    bottom: 32px;
    right: 37px;
}
.box.chat-modal .pointer-chat svg{
    width: 22px;
    height: 22px;
    color: var(--logo-red);
    cursor: pointer;
}

.baloon.client-sender {
    float: right;
    background: #f14f5c !important;
    color: #fff !important;
    margin-right: 20px !important;
    width: 100% !important;
    align-self: end;
}

.ballon-client.message-hour {
    text-align: right;
    margin-right: 20px;
    font-size: 12px;
    color: #776969;
}

.ballon-lawyer.message-hour {
    text-align: left;
    margin-left: 20px;
    font-size: 12px;
    color: #776969;
}