div#dataprivacy-doc-send-button {
    margin: 50px 0px 0px 50px;
}

div#dataprivacy-doc-send-button button {
    height: 150px;
    width: 180px;
    background: #2D4677;
    color: #fff;
    border: none;
    font-weight: bold;
    border-radius: 15px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

div#dataprivacy-doc-send-button button img {
    margin-bottom: 20px;
}

div#dataprivacy-doc-send-button button{
    cursor: pointer;
}

div#upload-box-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 50px auto;
}

button.attatch-file {
    margin: 20px 0px 20px 0px;
    padding: 15px;
    background: #9997A0;
    border: navajowhite;
    border-radius: 10px;
    font-size: 18px;
}

div#upload-box-body input, div#upload-box-body textarea {
    border: 1px solid #D3D2D7;
    padding: 5px;
    border-radius: 10px;
}

.attatch-file {
    margin: 20px 0px 20px 0px;
    padding: 15px;
    background: #9997A0;
    border: navajowhite;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
}

div#upload-box-body input, div#upload-box-body textarea {
    border: 1px solid #D3D2D7;
    padding: 5px;
    border-radius: 10px;
}

button.send-form-file {
    margin: 20px 0px 20px 0px;
    padding:10px 80px;
    background: #2D4677;
    border: #2D4677;
    border-radius: 10px;
    font-size: 18px;
    color:#fff;
    cursor: pointer;
}
ul#sended-docs li {
    border-bottom: 1px solid;
    padding: 10px 0px;
    text-transform:capitalize
}