#demandChat #chat {
  margin-left: 0px;
  border-bottom: 1px solid #d3d2d7 !important;
  height: 60vh;
  overflow: hidden;
}
#demandChat .chat-container {
  width: 97%;
  margin: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

#demandChat .input-area {
  display: flex;
  align-items: center;
  padding: 10px;
}

#demandChat #message-input {
  flex: 1;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  max-height: 150px;
  line-height: 1.5;
}

#demandChat .icons {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

#demandChat .icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  margin-left: 10px;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#demandChat .icon-btn:hover {
  color: #000;
}

#demandChat .conversation {
  position: relative;
  z-index: 0;
  overflow: auto;
  height: 70vh;
  width: 100% !important;
}

#demandChat .conversation ::-webkit-scrollbar {
  transition: all 0.5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

#demandChat .conversation ::-webkit-scrollbar-track {
  background: transparent;
}

#demandChat .conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

#demandChat .conversation .conversation-container {
  height: calc(100% - 100px);
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
}

#demandChat .conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both;
}

/* Messages */

#demandChat .message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

#demandChat .message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

#demandChat .metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

#demandChat .metadata .time {
  color: rgba(0, 0, 0, 0.45);
  font-size: 11px;
  display: inline-block;
}

#demandChat .message:first-child {
  margin: 16px 0 8px;
}

#demandChat .message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

#demandChat .message.received .metadata {
  padding: 0 0 0 16px;
}

#demandChat .message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

#demandChat .message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

#demandChat .message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}
