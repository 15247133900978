.demand-card {
    width: 30%;
    height: 130px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 8px 2px rgb(0 0 0 / 10%);
    padding: 15px 18px;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    align-content: center;
    flex-wrap: nowrap;
}

.d-flex.justify-content-between.demand-card-wrapper h6 {
    width: 80%;
}


.demand-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 0;
}
.demand-card h6 {
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
    font-weight: 700;
    color: #4E4B59;
    margin: 0;
}
.demand-card .number {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #f14f5c;
}
.demand-card .link {
    font-family: 'Inter';
    font-size: 14px;
    line-height: 150%;
    color: #7683f6;
    cursor: pointer;
    font-weight: 700;
}
.demand-card .link:hover {
    text-decoration: underline;
}

.icon i{
  width: 32px;
  height: 32px;
  margin: 0 !important;
}
.icon.good {
  background-image: url('../../../assets/icons/good.svg');
}
.icon.atencion {
  background-image: url('../../../assets/icons/atencion.svg');
}
.icon.danger {
  background-image: url('../../../assets/icons/cancel.svg');
}
.icon.clock {
  background-image: url('../../../assets/icons/clock.svg');
}