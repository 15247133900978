.dm-bar-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.select-table {
    background: #FFFFFF;
    border: 1px solid #D3D2D7;
    box-sizing: border-box;
    border-radius: 8px;
    width: 300px;
    height: 40px;
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #D3D2D7;
}
.select-table em{
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #D3D2D7;
}

.col.s12.search-select-demands {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}
