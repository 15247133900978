.box {
    background-image: url("../../../assets/bg-FinishRegisterModal.png");
    background-size: cover;
    border-radius: 8px 8px 16px 16px;
    width: 573px !important;
    height: 348px;
    text-align: center;
    padding: 0 !important;
}
.css-1wnsr1i {
    border: none !important;
}
.box h2 {
    font-weight: 500;
    font-size: 24px;
    color: white;
    width: 400px;
    display: block;
    margin: 10px auto 10px !important;
}
.box h2 strong {
    font-weight: 700;
}
.box img {
    width: 220px;
    height: 177px;
}
.box p{
    font-weight: normal;
    font-size: 18px;
    margin: 10px 72.5px;
}
.box .x-icon {
    position: absolute;
    top: 17px;
    right: 17px;
    width: 23px;
    height: 23px;
    color: white;
    cursor: pointer;
}