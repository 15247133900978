div#clients-container {
    width: 90%;
    margin: 45px;
    height:400px;
    background: #FFFFFF;
    overflow-y:scroll;
    overflow-x:hidden;
    border-radius: 16px;
    box-shadow: 0px 1px 5px #e0e0e0;
    position: relative;
    font-family: 'Inter';
}

#clients-container-header{
    background: #FAFAFA;
    display: flex;
    font-size: 18px;
    color: #4E4B59;
    padding: 10px 0;
}

#clients-container-body{
    display: inline;
    background: #fff;
    font-size: 18px;
    color:#9997A0;
}


#clients-container-header th{
    width: 50%;
    text-align: center;
}

#clients-container-body tr {
    display: flex;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #E0E0E0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #9997A0;
}

#clients-container-body td {
    width: 50%;
    text-align: center;
}

tfoot#data-privacy-clients-container-footer .pagination {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
}

tfoot#data-privacy-clients-container-footer tr th {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    margin-top: 5px;
    cursor: pointer;
}

.pagination div:hover, .pagination svg:hover {
    color: #f14f5c;
}
tfoot#data-privacy-clients-container-footer tr th div div {
    margin: 0px 5px;
}

tfoot#data-privacy-clients-container-footer {
    width: 100%;
    display: flex;
    bottom:0;
}

tfoot#data-privacy-clients-container-footer tr {
    border-bottom: none;
    display: flex;
    width: 100%;
}

tbody#clients-container-body tr a {
    width: 100%;
    display: flex;
}

tbody#clients-container-body h2 {
    display: flex;
    height: 60%;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}