input[type=file] {
    color: rgba(0, 0, 0, 0);
    opacity:0 ;
    display: none;
}

.file-list {
    margin-top: 3px;
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    justify-content: left;
}

.file-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin-bottom: 10px;
    border: 1px solid #d1d1d1;
    border-radius: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 11px;
    background-color: buttonface;
}

.icon {
    margin-right: 10px;
}
