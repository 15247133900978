.info-demand-view {
    padding: 32px 40px 20px !important;
    overflow-y: auto;
    display: block !important;
}
.info-demand-view .ui.table{
    background-color: transparent;
}
.info-demand-view .info-container {
    display: flex;
    flex-direction: column;
}
.info-demand-view .info-container .inform, .table-history span{
    font-size: 12px;
    color: var(--login-lgt-gray);
    margin: 10px 0;
}
.info-demand-view .info-container .title{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0;
}
.info-demand-view .info-container .title h2{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    width: 95%;
    text-align: start;
    color: #4E4B59;
}
.info-demand-view .info-container .title .acao{
    text-align: end;
}
.info-demand-view .info-container .title .acao .justificativa-text{
    text-align: end !important;
    position: absolute;
    width: 432px;
    margin: 0;
    font-size: 12px;
    color: #4E4B59;
    right: 40px;
}
.info-demand-view .info-container .title .acao .select-table{
    text-align: start;
    width: fit-content;
    border: none !important;
    box-shadow: none;
}
.info-demand-view .info-container .title .acao .select-table .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: none !important;
    box-shadow:none !important;

}
.info-demand-view .info-container .title .acao .select-table input:not([type]):focus:not([readonly]){
    border: none;
}
.justificativa {
    box-shadow: none;
    width: 524px;
    background-color: white;
    min-height: 224px;
    position: fixed !important;
    display: flex;
    top: 240px;
    right: 0;
    align-items: center;
    flex-direction: column;
    transform: translate(-50%, -50%);
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.18);
    border-radius: 8px;
}
.justificativa.d-none{
    display: none !important;
}
.justificativa p{
    font-weight: normal;
    font-size: 14px;
    color: #9CA5FB;
    margin-top: 24px !important;
    margin: 0;
}
.justificativa textarea{
    width: 475px !important;
    min-height: 100px;
    max-height: 200px;
    padding: 15px;
    border: 1px solid #D3D2D7;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 16px;
}
.justificativa  textarea:focus-visible{
    outline: 2px solid #D3D2D7;
}
.justificativa  button{
    border: none;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: var(--logo-red);
    width: 86px;
    height: 32px;
    color: white;
    font-weight: 700;
    display: block;
    margin: 15px;
    margin-left: auto;
}
.justificativa  button:disabled{
    background-color: #FAFAFA !important;
    color: #D3D2D7 !important;
}

.info-demand-view .info-container .data, .info-demand-view .info-container .obs{
    text-align: start;
}

.info-demand-view .info-container .data strong{
    font-weight: bold;
    font-size: 16px;
}
.info-demand-view .info-container .obs p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    margin: 0 !important;
    word-break: break-all;
}

.info-demand-view .info-container .valor {
    text-align: end;
    margin-top: 35px;
    margin-left: auto;
}
.info-demand-view .info-container .valor strong{
    font-weight: bold;
    font-size: 24px;
    color: #F14F5C !important;
}
.info-demand-view .service-detail{
    font-weight: 700;
    font-size: 16px;
    color: #7683F6;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.info-demand-view .service-detail svg{
    margin-left: 5px !important;
}
.table-history {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
}
.table-history span{
    text-align: start;
}
.table-history p{
    margin: 0;
    font-size: 16px;
}
.my-demands .loader {
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.info-demand-view .btn-green {
  font-family: "Inter";
  width: 80px;
  height: 32px;
  background: #44E47A;
  border-radius: 4px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  color: white;
  cursor: pointer;
}
.service-chat {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}
.service-chat span{
    color: #7683F6 !important;
    font-family: "Inter";
    font-style: italic;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    cursor: pointer;
}

#table-main .pagination .selected {
    font-weight: bold;
    color: #000000;
}