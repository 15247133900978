.baloon-info {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    margin: 30px 40px;
    width: 90%;
    padding: 10px;
    display: flex;
    align-items: center;
    height: 91px;
}
.baloon-info .bar {
    height: 100%;
    width: 4px;
    background-color: #7683F6;
    border-radius: 16px;
    margin-left: 30px;
    margin-right: 10px;
}
.baloon-info .icon svg{
    width: 28px;
    height: 28px;
    color: var(--advlogin-lgt-blue);
    margin: 0 24px;
    font-weight: 700;
}
.baloon-info p {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 4px;
}
.baloon-info button {
    width: 54px;
    height: 20px;
    font-size: 9px;
}