#table-main {
  margin: 0 40px;
  width: 90%;
  max-width: 100%;
  border-radius: 16px;
  overflow-x: scroll;
}

tr.worked-before-with-lawyer {
  border-collapse: collapse !important;
  border: 2px solid yellow;
}

#table-main .ui.table {
  border-collapse: collapse !important;
}

#table-main .client-table th, #table-main .client-table td {
  text-align: start ;
}
#table-main .client-table .person-type {
  text-align: center ;
  overflow: hidden;
  white-space: nowrap;
}
#table-main .client-table .person-name:hover {
  text-decoration: underline;
  cursor: pointer;
}
#table-main .ui.table thead tr:first-child>th:last-child{
  border-radius: 0px 16px 0px 0px;
}
#table-main .ui.table thead tr:first-child>th:first-child{
  border-radius: 16px 0px 0px 0px;
}
#table-main table {
  border-radius: 16px;
}
#table-main .tb-head th{
  background-color: #F1F1F1;
}
#table-main th {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: var(--login-drk-gray);
  text-align: center;
}
#table-main td {
  font-family: "Inter";
  font-weight: normal;
  font-size: 16px;
  color: var(--login-lgt-gray);
  text-align: center;
}
#table-main tfoot th {
  border-radius: 0 0 16px 16px;
  background-color: white;
}

#table-main button {
  font-family: "Inter";
  width: 80px;
  height: 32px;
  background: #F14F5C;
  border-radius: 4px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  color: white;
  cursor: pointer;
}
#table-main .ui.pagination.menu .item {
  min-width: 0 !important;
}
#table-main .ui.menu .item {
  min-width: 0 !important;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
#table-main .pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  color: var(--login-lgt-gray)
}
#table-main .pagination div{
  margin: 0 8px;
}
#table-main .pagination path{
  width: 16px;
  height: 16px;
}
.empty-table {
  height: 356px;
}
.empty-table span {
  position: absolute;
  font-family: "Inter";
  left: 94px;
  right: 0;
  text-align: center;
  margin-top: 100px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  transition: 0.25s ease all;
}
.container-width-sbopen .empty-table span {
    left: 244px;
}

.auction-icon {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.table-icon {
  margin-right: 10px;
}

.table-icon svg {
    fill: var(--logo-red);
    height: 16px;
    width: 16px;
}
.table-btn:disabled {
    background-color: #FAFAFA !important;
    color: #D3D2D7 !important;
}
#table-main .demands .table-btn-demands-auction {
  font-family: "Inter";
  width: 80px;
  height: 32px;
  background: #44E47A;
  border-radius: 4px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 995px){
  .ui.table:not(.unstackable) {
    padding: 0;
  }
}

@media only screen and (max-width: 995px){
  .ui.table:not(.unstackable) {
    padding: 0;
  }
}

@media only screen and (max-width: 995px) {
  .ui.table:not(.unstackable) {
    width: 100%;
  }
}

@media only screen and (max-width: 995px) {
  .ui.stackable.cards {
    display:block!important
  }

  .ui.stackable.cards .card:first-child {
    margin-top: 0!important
  }

  .ui.stackable.cards>.card {
    display: block!important;
    height: auto!important;
    margin: 1em 1em;
    padding: 0!important;
    width: calc(100% - 2em)!important
  }
}