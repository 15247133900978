.box.finish{
    background-image: none !important;
    height: 162px !important;
}
.box.finish h2{
    color: #4E4B59;
    font-weight: 600
}
.box.finish span{
    color: #4E4B59;
    font-weight: 700;
}
.return-modal h2{
    font-weight: bold;
    font-size: 24px;
    margin-top: 12px !important;
}
.return-modal p{
    font-weight: bold;
    font-size: 20px;
    margin-top: 0 !important;
}
.return-modal button {
    padding: 10px 22px;
    border: none;
    margin: 0 12px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    color: white;
    background-color: var(--advlogin-drk-blue);
    border-radius: 4px;
}
.return-modal .cancel {
    border: 1px solid #9997A0 !important;
    color: #9997A0 !important;
    background-color: transparent !important;
}
.return-modal.finish h2{
    margin-top: 56px !important;
}