:root {
    --bg-nav: #fff;
    --icon-color: #9997A0;
    --icon-active: #F14F5C;
}

.nav-content {
    display: flex;
}



.nav-top {
    height: 65px !important;
    width: calc(100vw - 96px) !important;
    display: flex !important;
    justify-content: space-between;
    box-shadow: inset 0px -1px 0px #9997A0;
    align-items: center;
    color: var(--icon-active);
    transition: all ease 0.35s;
}
.nav-top.open {
    width: calc(100vw - 244px) !important;
}
.nav-top h2 {
    background-color: var(--bg-nav);
    font-weight: 600;
    font-size: 24px;
    color: var(--icon-active);
    margin-left: 40px;
}
.nav-top .icons {
    display: flex;
    align-items: center;
}
.nav-top .icons div{
    margin: 0 10px !important;
    cursor: pointer;
}

.nav-top .profile-info {
    cursor: auto;
}
.nav-top .profile-info img{
    cursor: auto;
    height: 36px;
    width: 36px;
    display: block;
    margin: auto;
}

.nav-top .icons svg {
    width: 18px;
    height: 18px;
}
.nav-top .icons .ui.dropdown .menu {
    left: -140px;
    top: 63px;
    border-top: none !important;
    border: #9997a0 1px solid;
    box-shadow: none;
    border-radius: 0 0 6px 6px;
    padding-bottom: 10px;
    color: var(--login-gray)!important;
}
.nav-top .icons .ui.dropdown .menu .item{
    color: var(--login-gray)!important;
    padding: 0 16px;
}
.nav-top .icons .ui.dropdown .menu .item:hover {
    background-color: white;
    color: var(--icon-active) !important;
}
.nav-left {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    left: 0;
    top: 0;
    height: 100vh;
    width: 96px;
    background-color: var(--bg-nav);
    box-shadow: inset -1px 0px 0px #E0E0E0;
    transition: all ease 0.35s;
    overflow-y:scroll;
    z-index: 98 !important;
}
.nav-left::-webkit-scrollbar{
    -webkit-appearance:none;
    width:0 !important;
    height:10px
}

.nav-left.open {
    width: 244px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.nav-left.open button {
    width: 100%;
}
.nav-left.open .logo{
    max-width: 152px;
    max-height: 32.76px;
}

ul.submenu-dataprivacy div.icon-container {
    margin:0px !important;
    padding:8px 30px !important;
}


ul.submenu-dataprivacy{
    margin-bottom:60px;
}

span#arrow-down-dp-menu {
    font-size: 18px;
}

.nav-left .line {
    background-color: var(--icon-active);
    height: 2px;
    width: 80%;

}
.nav-left .nav-icon {
    width: 100%;
}
.nav-left .nav-icon .icon-container{
    margin-top: 20px;
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
}
.nav-left.open .nav-icon .icon-container{
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: flex-start;
}
.nav-left .link-active .nav-icon {
    width: 100%;
    background: #FBF9F6;
    box-shadow: inset 3px 0px 0px var(--icon-active);
}
.nav-left.open .nav-icon .icon-container {
    display: flex;
    align-items: center;
    position: relative;
    left: 36px;
}
.nav-left .link {
    width: 100%;
}
.nav-left.open .nav-icon p{
    font-weight: 500;
    font-size: 14px;
    color: #9997A0;
    padding-left: 16px;
    white-space: nowrap;
}
.nav-left .link-active .nav-icon {
    width: calc(100% - 1px);
    color: var(--icon-active);
}
.nav-left .link-active .nav-icon p {
    color: var(--icon-active);
}
.nav-left .nav-icon svg{
    fill: #9997A0;
}
.nav-left .link-active .nav-icon svg{
    fill: var(--icon-active);
    stroke: var(--icon-active);
}
.nav-left button {
    width: 30px;
    height: 30px;
    border: 0;
    background-color: #fff;
    display: block;
    margin: 16px auto;
}
.nav-left .nav-logo {
    height: 65px;

}
.nav-left .expandir {
    margin-top: auto;
    margin-bottom: 24px;
    color: var(--icon-color);
    cursor: pointer;
    font-size: 16px;
}
.nav-left .expandir.recuar{
    margin-top: auto;
    display: flex;
    margin-bottom: 24px;
    color: var(--icon-color)
}
.nav-left .expandir svg{
    margin: 0 auto;
    display: block;
    height: 20px;
    width: 20px;
}
button#menu-button i {
    font-size: 50px !important;
    padding: 6.1px;
    background: var(--bg-nav);
    display: flex;
    height: 64px;
    justify-content: center;
    align-items: center;
}

ul#slide-out {
    background: var(--bg-nav);
}

ul#slide-out li a, ul#slide-out li i {
    color: var(--icon-active);
}

.nav-wrapper {
    background: var(--bg-nav);
}

.sidenav .user-view .circle {
    object-fit: cover;
    background: #fff;
}

div#sidenav .col.s11 nav {
    box-shadow: none;
}

.input-field .prefix.active {
    color: var(--bg-nav) !important;
}

div#bonuz-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 64px;
}

div#bonuz-logo {
    background: var(--bg-nav)!important;
}

div#bg-film {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(34, 34, 34, 0.7);
    max-width: 100%;
    position: absolute;
    top: 0;
}
.popover.notificacoes {
    position: absolute;
    top:64px;
    right: 30px;
    width: 374px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 15px;
    z-index: 999999;
}

.popover.notificacoes {
    height: 400px !important;
}

.popover-notificacoes-body {
    height: 350px !important;
    overflow-y: scroll;
}

.ring-notifications{
    width: 28px !important;
    height: 28px !important;
    display: flex !important;
    animation: ring !important;
}

@keyframes rotating {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(-35deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(35deg);
    }

    100% {
        transform: rotate(0deg);
    }
}
.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.popover.notificacoes {
    position: absolute;
    top:56px;
    right: 30px;
    width: 374px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: auto;
}
.popover.notificacoes .header{
    color: #9997A0;
    height: 36px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 1px solid #D3D2D7;
    margin-bottom: 10px !important;
}
.popover.notificacoes .header p{
    margin-left: 10px;
    
}

p.notification-link {
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 15px;
    margin-top: 10px;
    text-transform: uppercase;
}

p.notification-link > button {
    background: none;
    border: none;
    margin-left: 10px;
    color: #f1505d;
    text-transform: uppercase;
    font-weight: bold;
}

ul#submenu-dp.closed, ul.closed#submenu-dp li, ul.closed#submenu-dp li p {
    height: 0;
    font-size: 0px;
}

nav.nav-wrapper.nav-top.closed.container-width {
    width: 97% !important;
}

.countRequests{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: #F14F5C;
    font-size: 10px;
    font-weight: bold;
    margin-left: 5px;
    color: #fff;
    font-weight: bold;
    }