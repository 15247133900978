/* Estilos gerais */
.card.dashboard-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 10px;
}
  
.userCard {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px
 }
  
/* Estilos do card do usuário */
.userImage {
    margin-right: 20px;
}
  
.dashboard-profile-image {
    max-width: 100px;
    height: auto;
    border-radius: 50%;
}
  
.userInfo {
    flex: 1;
}
  
.userInfo h2 {
    color: var(--ui-colors-gray-02, #4E4B59);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 27px */
    letter-spacing: -0.09px;
}
  
.userInfo p {
    font-size: 16px;
    color: #777;
}
  
.userInfo a.waves-effect.waves-light.btn {
    background-color: #007bff;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}
  
.userInfo a.waves-effect.waves-light.btn:hover {
    background-color: #0056b3;
}
  
/* Estilos do card de progresso das demandas */
.demand-progress-card {
    padding: 10px;
    margin: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    /* ... (outros estilos) ... */
}

.demand-progress-card h2 {
    font-size: 24px;
    color: #333;
    text-align: left; /* Alinhado à esquerda */
}

.progress-bar {
    display: flex;
    height: 20px;
    margin-top: 20px;
    align-items: center;
}

.status-bar {
    height: 100%;
}

.legend {
    display: flex;
    margin-top: 10px; /* Reduzido o espaço superior */
    align-items: center; /* Alinhado à esquerda */
    justify-content: flex-start; /* Alinhado à esquerda */
}

.status-item {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.status-dot {
    width: 10px; /* Restaurado o tamanho original do ícone */
    height: 10px; /* Restaurado o tamanho original do ícone */
    border-radius: 50%;
    margin-right: 5px;
}

.status-name {
    font-size: 16px;
    color: #333;
}

/* Estilizando as quatro divs dos status */
.status-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    width: 100%;
    margin-bottom: 20px;
}

.status {
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
}

.status-icon {
    flex-shrink: 0;
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: red;
    color: #FFF;
    font-family: 'Material Icons';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    margin-top: 10px;
}

.status-count {
    font-size: 25px;
    font-weight: bold;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}

.status h3 {
    margin-top: 10px; /* Espaço entre o título e o ícone */
    margin-bottom: 5px;
}

.status-icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    display: inline-block; /* Alterado para inline-block para permitir definir width e height */
    border-radius: 8px;
    background: #F82929;
    color: #FFF;
    font-family: 'Material Icons';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* Centraliza verticalmente o ícone */
    text-align: center; /* Centraliza horizontalmente o ícone */
}

.status-icon-warning {
    background: red;
    color: #FFF;
}

.status-icon-pending {
    background: yellow;
    color: #FFF;
}

.status-icon-progress {
    background: purple;
    color: #FFF;
}

.status-icon-done {
    background: green;
    color: #FFF;
}

.waves-link {
    color: #4183c4;
}

/* Estilo para as estrelas */
.star {
    font-size: 24px; /* Aumenta o tamanho das estrelas */
    color: #FFD700; /* Cor dourada das estrelas */
    margin-right: 4px; /* Espaço entre as estrelas */
  }
  
  /* Estilo para as estrelas cinzas */
  .star.gray {
    color: #ccc; /* Cor cinza para as estrelas cinzas */
  }
  
  /* Estilo para centralizar o número abaixo das estrelas */
  .userRating {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px; /* Espaço entre as estrelas e o número */
  }
  
  .userRatingText {
    font-size: 18px; /* Tamanho do texto da nota */
    color: #333; /* Cor do texto da nota */
    text-align: center; /* Centraliza o texto */
  }
  

  /* Estilos para a lista de notificações de chat não verificadas */
  .chatNotificationCard {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 20px;
    width: 98%; /* Adicione esta regra para ocupar 100% da largura */
    text-align: left; /* Alinhar o conteúdo à esquerda */
  }
  
  
  .chatNotificationCard h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .chatNotificationCard ul {
    list-style-type: none;
    padding: 0;
  }
  
  .chatNotificationCard li {
    margin-bottom: 10px;
  }
  
  .chatNotificationCard button {
    background-color: #007bff;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .chatNotificationCard button:hover {
    background-color: #0056b3;
  }
  
  .pagination {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--login-lgt-gray);
}

#chat-notifications .pagination.selected {
    font-weight: bold;
    color: #000000;
}

#chat-notifications .pagination div{
    margin: 0 8px;
}
#chat-notifications .pagination path{
    width: 16px;
    height: 16px;
}
#chat-notifications .pagination .selected {
    font-weight: bold;
    color: #000000;
}
