div#dataprivacy-chat-button {
    margin: 50px 0px 0px 0px;
}

div#dataprivacy-chat-button button {
    cursor:pointer;
    height: 150px;
    width: 180px;
    background: #2D4677;
    color: #fff;
    border: none;
    font-weight: bold;
    border-radius: 15px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

div#dataprivacy-chat-button button img {
    margin-bottom: 20px;
}