div#lawyer-notifications {
    position: absolute;
    z-index: 999;
    background: rgba(1,1,1,0.5);
    width: 100%;
    height: 100vh;
}

div#lawyer-notifications-box {
    z-index: 99;
    position: fixed;
    top: 10vh;
    left: 0;
    right: 0;
    bottom: 0;
    width: 1024px;
    background: #fff;
    height: 80vh;
    margin: 0 auto;
    border-radius:10px;
    padding:30px;
}

#lawyer-notifications.closed{
    display: none;
}

.close-button {
    text-align: right;
    float: right;
}

.close-button button {
    padding: 12px;
    font-size: 22px;
    background: #fff;
    border: 1px solid #111;
    border-radius: 30px;
    height: 13px;
    width: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.notification-link-modal{
    cursor: pointer;
}

.popover-notificacoes-body-modal h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
}
p.notification-link,p.notification-link *{
    cursor:pointer;
}

p.notification-link:hover {
    background: #f1e6e6;
    border-radius: 15px;
    border: 5px solid #f1e6e6;
    box-shadow: 0px 0px 14px 1px;
    transition: .2s linear;
}

#lawyer-notifications-box .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    cursor: pointer;
    color: var(--login-lgt-gray);
    position: absolute;
    bottom: 15px;
    left: -50;
}
#lawyer-notifications-box .pagination div{
    margin: 0 8px;
}
#lawyer-notifications-box .pagination path{
    width: 16px;
    height: 16px;
}
#lawyer-notifications-box .pagination .selected {
    font-weight: bold;
    color: #000000;
}

.close-button-mini {
    text-align: right;
    float: right;
    padding: 10px;
    font-size: 18px;
    background: #FFFFFF !important;
    border: 1px solid #701313 !important;
    border-radius: 30px !important;
    height: 7px;
    width: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.cliqueaqui {
    text-transform: uppercase;
    color: red;
    font-weight: bold;
    margin-left: 10px;
}
span.text {
    font-weight: normal;
}