.activitie-list-item {
    font-size: 22px;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

div#demands-status-general {
    margin: 45px auto;
    display: flex;
}

@media screen and (max-width: 768px) {
    .data-privacy-modal .MuiBox-root {
        width: 100%;
        top: 45%;
        right: 50%;
        left: 50%;
    }

    .data-privacy-modal {
        margin: 80px;
    }
}