#table-modal thead th {
    background-color: transparent;
    border: none;
    box-shadow: none;
}
#table-modal br {
    height: 2px;
}
#table-modal.ui.table {
    border: none;
}
#table-modal tbody .tr-button td{
    height: 68px;
    box-shadow: none;
    border-top: 1px solid #D3D2D7;
    border-bottom: 1px solid #D3D2D7;
    font-size: 14px;
    color: #4E4B59;
    cursor: pointer;
}
.table-history #table-modal tbody td{
    height: 40px;
    width: auto;
    box-shadow: none;
    border-top: none;
    border-bottom: none;
    font-size: 16px !important;
    color: #4E4B59;
    cursor: pointer;
    padding: 0 10px;
}
.table-history .mensagem-icon{
    margin-left: 20px;
    cursor: pointer;
}
.table-history .table-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.table-history .table-container .anexo-file{
    color: #7683F6;
    font-size: 16px;
    cursor: pointer;
}
.demand-auction thead th {
    color: #9997A0 !important;
    font-size: 12px;
    padding: 10px 0 !important;
}
.demand-auction .tr td {
    padding: 0 !important;
}