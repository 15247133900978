.status-chip {
    height: 60px;
    width: 100%;
    max-width: 100%;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    background: transparent;
}
.status-chip.in_progress {
    color: #5A67DF;
    background-color: #D6DAFF;
}
.status-chip.pending {
    color: #350A7C;
    background-color: #E9D3FB;
}
.status-chip.canceled {
    color: #C31E1E;
    background-color: #F8D0D0;
}
.status-chip.done {
  color: #49A96A;
  background-color: #D6FBD3;
}
.status-chip.concluido {
    color: #49A96A;
    background-color: #D6FBD3;
}
.status-chip.draft {
    color: #FF6007;
    background-color: #FFE7D6;
}
.status-chip.stand_by {
    color: #F4C51F;
    background-color: #FFF9D7;
}
.status-chip.in_estimating {
  color: #F0BA06;
  background-color: #FFF6D7;
}
.status-chip.waiting_approval {
  color: #5A5A5A;
  background-color: #DCDCDC;
}
.status-chip.in_review {
  color: #68480A;
  background-color: #FBEDD3;
}
.status-chip.demand_sent {
  color: #3AC59B;
  background-color: #D3FBE5;
}
.status-chip.waiting_estimate_approval {
  color: #D4169F;
  background-color: #FFD7F4;
}
.status-chip.waiting_lawyer_acceptance {
  color: #D4169F;
  background-color: #FFD7F4;
}

@media only screen and (max-width : 1024px) {
    .status-chip{
        background:transparent !important;
    }
}