@font-face {
  font-family: Roboto;
  src: url('../../assets/fonts/Roboto-Regular.ttf');
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

h2, label, p {
  font-family: "Inter";
}

h2, label {
  color: var(--login-drk-gray);
}
p {
  color: var(--login-gray);
}



#login-main{
/* background: url("../../assets/bg-advlogin.png");
background-size: cover;
width: 100%;
min-height: 0;
padding: 32px 0;
overflow-y: auto;
overflow-x: auto; */

/* display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: center; */


width: 100%;
min-height: 0;
height: 100vh;
display: flex;
flex-direction: row;
overflow-y: auto;
overflow-x: auto;    
align-items: flex-start;
justify-content: center;
background: url("../../assets/bg-advlogin.svg");
background-repeat: no-repeat;
background-position: center center;
background-size: cover;
}

.container {
overflow-y: auto;  
overflow-x: auto;
display: flex;
flex-direction: row;
align-items: stretch;
justify-content: stretch;
padding: 32px 0;
}

#login-title {
  font-family: 'Roboto';
  color: #f14f5c;
  font-weight: bold;
}

#login-content {
  display: flex;
  flex-direction: row;
  padding: 1px;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 2px 14px 2px rgba(80, 45, 45, 0.15);
  border-radius: 8px;
  background-color: #FFFFFF;
  overflow-y: auto;  
  overflow-x: auto;

  /* display: flex;
  padding: 12px 8px;
  background: #FFFFFF;
  box-shadow: 0px 2px 14px 2px rgba(80, 45, 45, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch; */
}

#login-content.forgot {
  height: 482px;
}

img#grass-owl {
  width: 350px;
  position: absolute;
  bottom: 0;
  right: 40%;
  z-index: 1;
}

img#grass-img {
  width: 430px;
  position: absolute;
  bottom: 0;
}

#login-main .coruja {
  margin: 10px;
}

#login-main .coruja img {
  display: block;
}
div#login-presentation {
  display: flex;
}

div#login-content div#login-presentation h1 {
  position: absolute;
  top: 15%;
}

div#login-main {
  display: flex;
  align-items: center;
}

#eye1 {
  height: 25px;
  width: 25px;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  right: 66%;
  z-index: 9999;
  bottom: 21%;
}

#eye2 {
  height: 25px;
  width: 25px;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  right: 58%;
  z-index: 9999;
  bottom: 24%;
}