.led-icon{
    width: 45px;
    height: 45px;
    background-image: url("../../assets/Ellipsewt.png");
    display: flex;
    fill: #9997A0;
}
.led-icon svg {
    display: block;
    margin: auto;
    fill: #9997A0;
}
.led-icon.negative{
    background-image: url('../../assets/Ellipsered.png');
    width: 45px;
    height: 45px;
}
.led-icon.negative svg{
    fill: #fff;
}
.led-icon.positive{
    background-image: url("../../assets/Ellipsegr.png");
    width: 45px;
    height: 45px;
    fill: #49A96A;
}
.led-icon.positive svg{
    fill: #49A96A;
}
