.data-privacy-wrapper .little-card{
  width: 160px;
  height: 182px;
  background: #FFFFFF;
  border-bottom: 2px solid #FB6D06;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.data-privacy-wrapper .btn-plan{
  color: #FFFFFF;
  background: #FB6D06;
  border-radius: 4px;
  padding: 8px 16px
}

.premium .data-privacy-wrapper .btn-plan{
  color: #FFFFFF;
  background: #000;
  border-radius: 4px;
  padding: 8px 16px
}

.data-privacy-wrapper .info-toggle {
  display: block;
  height: 16px;
  width: 16px;
  background-color: #D4D8FF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-privacy-wrapper .info-toggle p{
  color: #7683F6 !important;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 900;
}
.data-privacy-wrapper .little-card svg{
  fill: #FB6D06;
  margin: 0 auto;
  display: block;
}

.data-privacy-wrapper .little-card.upgrade svg{
  fill: #9997a4;
}


.data-privacy-wrapper .little-card .notify{
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FB6D06;
}


.data-privacy-wrapper .little-card{
  height: 182px;
  background: #FFFFFF;
  border-bottom: 2px solid #FB6D06;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  position: relative;
}


.data-privacy-wrapper .little-card p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #7A7786;
  margin-bottom: 10px;
  padding:0 10px;
}

.data-privacy-wrapper .little-card .bottom-position{
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.data-privacy-wrapper .little-card button{
  display: block;
}

.data-privacy-wrapper .little-card .link {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  text-decoration-line: underline;
  text-transform: uppercase;  
  color: #FB6D06;
  margin-bottom: 0;
}

.data-privacy-wrapper .progress-circle {
  width: 10px;
  margin-top: 4px;
  margin-right: 6px;
  display: block;
  height: 10px;
  border-radius: 75%;
}

.premium .data-privacy-wrapper .little-card svg{
  fill: #000;
}
.premium .data-privacy-wrapper .little-card{
  border-bottom: 2px solid #000 ;
}
.premium .data-privacy-wrapper .little-card .notify,
.premium .data-privacy-wrapper .little-card .link {
  color: #000;
}

.data-privacy-wrapper .fake-button{
  background: transparent;
  border: none;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  text-decoration-line: underline;
  text-transform: uppercase;  
  color: #FB6D06;
  margin-bottom: 10px;
}

.data-privacy {
  margin: 45px;
  width: 50vw;
}

.data-privacy-metrics-wrapper {
  margin:45px;
  padding: 20px;
  border-radius: 8px;
  font-family: 'Inter';
  width: 85%;
}

.data-privacy-metrics-wrapper h4 {
  font-size: 24px;
  font-weight: 700;
  color: #4E4B59;
}

.data-privacy-metrics-description {
  display: flex;
  gap: 20px;
}

.data-privacy-metrics-description-details{
  display: flex;
}

.data-privacy-metrics-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

#upcoming-activities{
  margin:45px;
}


@media screen and (max-width: 768px) {
  div#demands-status-general {
    margin: 45px;
    display: flex;
  }

  .d-flex.justify-content-between.demand-card-wrapper h6 {
    width: 80%;
  }

  div#demands-status-general {
    margin: 0px !important;
    flex-direction: column;
  }

  .demand-card {
    width: 85% !important;
  }

  .card.mb-3.p-3.data-privacy-metrics-wrapper {
    margin: 40px 13px;
  }

  div#clients-container {
    margin: 15px;
    width: 95%;
  }

  div#upcoming-events {
    margin: 15px;
  }
}