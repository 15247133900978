.input-content {
    padding-bottom: 10px;
    padding-top: 10px;
}

select.expertises-select.input-content.ui.left.icon.input.validate {
    min-height: 80px;
    margin-top: 20px;
}

input.select-dropdown.dropdown-trigger{
    display: none !important;
}

svg.caret {
    display: none;
}