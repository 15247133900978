.box.lance-modal {
    background-image: none;
    min-height: 300px;
    height: auto;
    padding: 15px !important;
}

.box.lance-modal h2{
    font-size: 20px;
    line-height: 124%;
    color: #4E4B59;
}
.box.lance-modal p{
    font-family: "Inter";
    font-size: 14px;
    text-align: center;
    color: #9997A0;
}
.box.lance-modal input:not([type="checkbox"]), .box.lance-modal textarea{
    border: 1px solid #D3D2D7;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    font-size: 14px;
}

.box.lance-modal textarea{
    height: 100px;
}

.box.lance-modal .line{
    width: 50px;
    background-color: #D3D2D7;
    height: 2px;
}
.lance-modal button {
    padding: 10px 22px;
    border: none;
    margin: 0 12px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    color: white;
    background-color: var(--logo-red);
    border-radius: 4px;
}
.lance-modal button:disabled {
    opacity: 0.7;
    background-color: var(--logo-red);
}
.lance-modal .cancel {
    border: 1px solid #9997A0 !important;
    color: #9997A0 !important;
    background-color: transparent !important;
}
.box.lance-modal.finish {
    height: 346px !important;
    background-image: url("../../../../assets/bg-FinishRegisterModal.png") !important;
}
.box.lance-modal.finish h2{
    color: #fff;
    margin: 36px auto 10px !important;
    width: 450px;
    font-size: 20px;
}
.box.lance-modal.finish p{
    font-size: 14px;
    color: #4E4B59;
    font-weight: 600;
}
.box.lance-modal.finish button{
    width: 360px;
    height: 48px;
    padding: 0;
    margin: 20px auto;
    background: #1A2C50;
    border-radius: 8px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    color: white;
    cursor: pointer;
}
.box.lance-modal img{
    width: 184px;
    height: 118px;
}
.circle-led {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0 8px;
}
.box.lance-modal input[type="checkbox"]{
    opacity: unset;
}

.box.lance-modal .checkbox-container {
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.box.lance-modal .checkbox-container input[type="checkbox"] {
   margin-right: 8px;
}
.box.lance-modal .checkbox-container label {
    transform: unset;
    position: unset;
    margin-left: 20px;
}