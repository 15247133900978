.services-container {
    padding-bottom: 40px;
}

.services-inner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.select-table {
    background: #FFFFFF;
    border: 1px solid #D3D2D7;
    box-sizing: border-box;
    border-radius: 8px;
    width: 300px;
    height: 40px;
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #D3D2D7;
}
.select-table em{
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #D3D2D7;
}
.services-inner-container .searchbar {
    width: 80% !important;
    margin: 16px auto;
}
.select-services-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.select-services-container .label-services {
    font-size: 16px;
    font-weight: 500;
    color: #4E4B59;
    margin: 0 8px;
}
.hintsbar {
    width: 94%;
}



.table-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 37px 0;
}
#table-services {
    width: 60%;
    margin: 0 27px 0 40px;
    border-radius: 16px;
}
#table-services .ui.table thead tr:first-child>th:first-child{
    border-radius: 16px 16px 0px 0px;
    padding: 14px 24px;
}
#table-clients-services .ui.table thead tr th{
    border-radius: 16px 16px 0px 0px;
    padding: 14px 24px;
}
#table-services table, #table-clients-services table {
    border-radius: 16px;
}
#table-services .tb-head th, #table-clients-services .tb-head th {
    background-color: #FFFFFF;
}
#table-services th{
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: var(--login-drk-gray);
    text-align: left;
}
#table-services td {
    font-family: "Inter";
    font-weight: normal;
    font-size: 16px;
    color: #4E4B59;
    text-align: left;
    border-top: none;
    padding: 16px 0 0 24px;
}
#table-services .text-service-price {
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
    color: #F14F5C;
    padding: 16px 0 0 24px;
}
#table-services .text-service-price-estimating {
    font-family: "Inter";
    font-size: 16px;
    padding: 16px 0 0 24px;
}
.input-field-services {
    margin: 8px 0;
    width: 30%;
}
.input-field-services input {
    background: #FFFFFF;
    border-radius: 8px;
    color: #9997A0;
}
#table-clients-services {
    margin: 0 40px 0 0;
    border-radius: 16px;
}
#table-clients-services tr{
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    color: #9997A0;
    text-align: left;
    padding: 16px 24px;
}

#table-clients-services button {
    font-family: "Inter";
    min-width: 80px;
    height: 32px;
    background: #F14F5C;
    border-radius: 4px;
    border: none;
    font-weight: bold;
    font-size: 14px;
    color: white;
    cursor: pointer;
    padding: 0 10px;
}
#table-services button {
    font-family: "Inter";
    min-width: 80px;
    height: 32px;
    background: #F14F5C;
    border-radius: 4px;
    border: none;
    font-weight: bold;
    font-size: 14px;
    color: white;
    cursor: pointer;
    margin: 16px 0;
    padding: 0 10px;
}
#table-clients-services .ui.table tbody tr td:last-child{
    text-align: right;
}



#table-clients-services tfoot th {
    border-radius: 0 0 16px 16px;
    background-color: white;
}
#table-clients-services .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    color: var(--login-lgt-gray)
}
#table-clients-services .pagination div{
    margin: 0 8px;
}
#table-clients-services .pagination path{
    width: 16px;
    height: 16px;
}

.input-textarea {
    margin: 0;
    width: 90%;
    border-color: #D8D8D8;
    color: darkgray;
}*
.input-textarea input {
    background: #FFFFFF;
    border-radius: 8px;
    color: #d7d7d7;
    /*width: 50%;*/
}








/* #table-services .ui.table tbody tr:first-child>td:last-child{
  border-radius: 0px 16px 0px 0px;
}
#table-services .ui.table tbody tr:first-child>td:first-child{
  border-radius: 16px 16px 0px 0px;
} */
/* #table-services tfoot th {
  border-radius: 0 0 16px 16px;
  background-color: white;
}

#table-services button {
  font-family: "Inter";
  width: 80px;
  height: 32px;
  background: #F14F5C;
  border-radius: 4px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  color: white;
  cursor: pointer;
}
#table-services .ui.pagination.menu .item {
  min-width: 0 !important;
}
#table-services .ui.menu .item {
  min-width: 0 !important;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
#table-services .pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  color: var(--login-lgt-gray)
}
#table-services .pagination div{
  margin: 0 8px;
}
#table-services .pagination path{
  width: 16px;
  height: 16px;
}
.empty-table {
  height: 356px;
}
.empty-table span {
  position: absolute;
  font-family: "Inter";
  left: 94px;
  right: 0;
  text-align: center;
  margin-top: 100px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  transition: 0.25s ease all;
}
.container-width-sbopen .empty-table span {
  left: 244px;
} */