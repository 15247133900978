.platinum-plan-client main {
    margin: 0 40px;
}
.platinum-plan-client main .first {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.platinum-plan-client  .first .card{
    padding: 0;
    width: 100%;
    max-width: 700px;
}
.platinum-plan-client  .first .card{
    width: 100%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px
}
.platinum-plan-client  .first .card header{
    background: #FAFAFA;
    border-bottom: 1px solid #D3D2D7;
    padding: 16px 24px;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    justify-content: space-between;
}
.platinum-plan-client  .first .card .content{
    padding: 16px 24px;
}
.platinum-plan-client  .first .card .content .little{
    margin: 0;
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #9997A0;
}
.platinum-plan-client  .second{
    margin-top: 30px;
    display: flex;
    width: 100%;
}

.platinum-plan-client  .second .tables {
    width: 100%;
    margin: 0 10px;
}

div#dataprivacy-doc-send-button {
    margin: 50px 0px 0px 50px;
}

div#dataprivacy-doc-send-button a {
    height: 150px;
    width: 180px;
    background: #2D4677;
    color: #fff;
    border: none;
    font-weight: bold;
    border-radius: 15px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

div#dataprivacy-doc-send-button a img {
    margin-bottom: 20px;
}

div#dataprivacy-doc-send-button a{
    cursor: pointer;
}