.client-details {
  background-image: none !important;
  max-height: 60vh !important;
  width: 910px !important;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  padding: 32px 40px 20px !important;
  overflow: auto;
}
.client-details p{
  font-size: 16px !important;
  color: #4E4B59;
  margin: 0 !important;
}
.client-details .service-detail{
  font-weight: 700;
  font-size: 16px;
  color: #7683F6;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.client-details .service-detail svg{
  margin-left: 4px;
}
.client-details .loader{
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.client-details .client-data{
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
}
.client-info-details .inform, .client-info-details p{
text-align: left;
text-overflow: ellipsis;
}
.client-details .obs .inform{
margin: 28px 0 -12px 0;
}