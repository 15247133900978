.accept-modal {
  height: 388px;
}
.accept-modal h2{
    font-weight: bold;
    font-size: 24px;
    margin-top: 33px !important;
}
.accept-modal p{
    font-weight: bold;
    font-size: 20px;
    margin-top: 0 !important;
}
.accept-modal button {
    padding: 10px 22px;
    border: none;
    margin: 0 12px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    color: white;
    background-color: var(--advlogin-drk-blue);
    border-radius: 4px;
}
.accept-modal .cancel {
    border: 1px solid #9997A0 !important;
    color: #9997A0 !important;
    background-color: transparent !important;
}

.accept-modal .spinner-grey {
    border: 4px solid rgba(0, 0, 0, 0.1); 
    border-top: 4px solid #000; 
    border-radius: 50%; 
    width: 12px; 
    height: 12px; 
    animation: spin 1s linear infinite;
}

.accept-modal .spinner-white {
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-top: 4px solid #fff;
    border-radius: 50%; 
    width: 12px; 
    height: 12px; 
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}